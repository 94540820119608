<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">营销活动</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">分享好友赢奖励</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">活动明细</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="display:flex;flex-direction: column;align-items: start;">
          <div class="searchbox">
            <div title="分享人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">分享人姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入分享人姓名"
                clearable
              />
            </div>
            <div title="分享人手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">分享人手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入分享人手机号"
                clearable
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="分享人姓名"
                fixed
                align="left"
                prop="userName"
                show-overflow-tooltip
              />
              <el-table-column
                label="分享人手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="分享次数"
                align="left"
                prop="shareNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="累计获得积分"
                align="left"
                prop="allPoint"
                show-overflow-tooltip
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ShareActivityDetails",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userName: "",
      mobile: "",
    };
  },
  computed: {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        shareId:this.$route.query.shareId
      };
      if (this.userName) {
          params.userName = this.userName;
      }
      if (this.mobile) {
          params.mobile = this.mobile;
      }
      this.doFetch({
        url: "/share/act-share/shareUserList",
        params,
        pageNum,
      },true,2);
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 30;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped></style>
